.custom-toast-progress-success{
    background-color: #ac59ff!important;
}

.custom-toast-success svg{
    fill: #ac59ff!important;
}

.custom-toast-progress-warning{
    background-color: #8bb5e0!important
}

.custom-toast-warning svg{
    fill: #8bb5e0!important
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    font-size: large;
  }
  
  *:focus {
    outline: 0;
  }
  
  html,
  body,
  #root {
    height: 100%;
  }

button:hover{
  cursor: pointer;
}

:root{
    /*Sistema - Paletas de cores*/
      --cor1: #ac59ff;
      --cor2: #4e1975;
      --cor3: #8bb5e0;
      --cor4: #fbfbfb;
      --cor5: #eeeeee;
      --cor6: #dddddd;
      --cor7: #666666;
      --cor8: #000000;
      --cor10: #3c90e3;    
}
/* Estilos gerais */
.main-container-modulos {
  border: 1px solid black;
  height: 100%;
  background-color: white;
  overflow-y: auto; /* Habilita a barra de rolagem vertical */
}

.modulos-header {
  height: 10%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 4rem;
  color: #4E1975;
}

.modulos-body {
  height: 80%;
  display: flex;
  flex-wrap: wrap; /* Permite que os cartões quebrem linha em telas menores */
  align-items: center;
  justify-content: center;
  overflow-y: auto; /* Habilita a barra de rolagem vertical se necessário */
}

.modulos-footer {
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: end;
  padding-right: 2rem;
}

.modulos-footer img {
  width: 35px;
  height: 35px;
}

.modulos-footer img:hover {
  cursor: pointer;
}

.modulos-header span {
  width: auto;
  font-size: 25px;
}

/* Media queries para dispositivos móveis */
@media (max-width: 768px) {
  .modulos-header {
      justify-content: center; /* Centraliza o texto no cabeçalho */
      padding-right: 0; /* Remove o padding direito */
      font-size: 20px; /* Ajusta o tamanho da fonte */
  }

  .modulos-body {
      flex-direction: column; /* Empilha os cartões verticalmente */
      height: auto; /* Permite que a altura se ajuste ao conteúdo */
      overflow-y: auto; /* Habilita a barra de rolagem vertical em dispositivos móveis */
  }

  .modulos-footer {
      justify-content: center; /* Centraliza o ícone de sair */
      padding-right: 0; /* Remove o padding direito */
  }
}

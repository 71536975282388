


.main-container-conteudo-status{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: calc(100vw - 250px);
    margin: 0;
    padding: 0;
    height: calc(100vh - 9vh);
}

.main-container-conteudo-status-open{    
    display: flex;    
    align-items: center;
    justify-content: center;    
    width: calc(100vw - 500px);
    margin: 0;
    padding: 0;
    height: calc(100vh - 9vh);
}

.report-style-class{
    height: 91vh;
    width: 99vw;
    padding: 1rem;
}

.sem-status-reports{    
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    border-radius: 50px;
    padding: 2rem;
}

.sem-status-reports p{
    padding-left: 1rem;
    margin: 2px;
}

.sem-status-reports span{
    padding-bottom: 2rem;
    font-size: 25px;
    font-weight: 500;
}

.p-dialog .p-dialog-content:last-of-type{
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
}

.p-dialog .p-dialog-header{
    background: #f1eeee;
}

.icon-sem-status-reports{
  color: var(--buttonColor);
}

.p-dialog .p-dialog-header .p-dialog-header-icon{
    color: var(--buttonColor);
}

.p-dialog-header-icon:hover{
    color:  #9d47f3!important;
}
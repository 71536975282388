.pessoa-juridica-header{
    padding-top: 2rem;
    /* border: 1px solid black;     */
    text-align: flex-start;
    font-family: var(--fontFamily);
    font-size: var(--seectionHeader);
}

.pessoa-juridica-conteudo input{
    border: none;
    border-bottom: 1px solid var(--cor7); 
    border-radius: 0; 
    outline: none;
    box-shadow: none; 
    background-color: unset;
    width: 100%;
}

.pessoa-juridica-conteudo input:focus{
    border: none!important;
}

.pessoa-juridica-botoes{
    border: 1px solid blue;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
        
}


.pessoa-juridica-conteudo div{
    display: flex;
    justify-content: space-between;
    /* border: 1px solid blue; */
}

.conteudo-cpf{
    margin-right: 2rem;
}

.conteudo-celular{
    margin-left: 2rem;
}

.align-items-center{
    margin-top: 0.5rem;
}

.align-items-center label{
    font-size: var(--aceiteSize);
    width: 100%;
    text-align: flex-start;
    font-family: var(--fontFamily);
    color: var(--cor8);
}

.toat-warning-senha hr{
    margin: 10px 0!important;
}

.info-senha{
    width: 100%;
    margin-top: 0.8rem;
    text-align: left;
    word-spacing: 0.4rem;
}

.p-inputtext:enabled:focus{
    box-shadow: none!important;
    border-bottom: 1px solid var(--cor7)!important;
}

.p-inputtext:hover{
    border-color: var(--cor1)!important;
}
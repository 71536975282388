.main-login-container{
    width: 100%;
    height: 100%;    
    background-color: white;
    
}

.main-login-header{
    height: 10%;    
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.main-login-header .logo-center {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 40px;
}

.main-login-header .logo-left {
    height: 70px;
    padding-left: 25px;
}
  
.main-login-header .logo-right {
    height: 100px;    
    
    padding-right: 25px;    
}

.logo-left,
.logo-right {
  max-width: 100%;
  height: auto;
}

.main-login-body{
    height: 80%;
    display: flex;
    flex-direction: row;
}

.main-login-footer{
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: end;
    padding-right: 2rem;
}

.login-left{
    height: 100%;
    width: 30%;
    position: relative;
}

.login-left-image {
    position: absolute;
    left: -200px;
    top: 0;
    height: 100%;
    background-size: contain;
  }

.login-center{
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.login-right{
    height: 100%;
    width: 30%;
}

.login-center-header{
    width: 50%;
    position: relative;
    display: inline-block;
    height: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

.header-line{
    border: 4px solid #AC59FF;;
    width: 50px;
}

.login-center-header div{
    display: flex;
    width: 50%;
    align-items: center;
    padding: 0 1.5rem;
    
}

.login-center-form{
    width: 100%;
    position: relative;
    height: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.login-center-footer{
    height: 25%;
    position: relative;
}

.login-center-form input{
    width: 350px;
    height: 45px;
    border-radius: 25px;
    margin: 0.6rem;
    border: none;
    background-color: rgb(233, 231, 231);
    padding: 1rem;
}

.login-center-form button{
    height: 35px;
    width: 350px;
    border-radius: 25px;
    border: none;
    background-color: #AC59FF;
    margin-top: 1rem;
}

.login-center-form button:hover{
    background-color: #994ce6;
}

.login-center-footer{
    display: flex;
    flex-direction: column;
    align-items: center;    
    position: relative;
}

.login-center-footer a{
    color: gray;
    margin: 0.3rem;
    text-decoration: none;
}

.main-login-footer img{
    width: 35px;
    height: 35px;
}

.login-center-footer a:hover{
    color: #AC59FF;
}

/* Estilos para dispositivos móveis */
@media (max-width: 768px) {
    .main-login-header {
      justify-content: center; 
    }
  
    .logo-left {
      display: none;
    }
  
    .logo-right {
      display: block;
      margin: 0 auto; 
    }
  }
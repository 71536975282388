.main-container-card-etapas{
    background-color: #F7F5F5;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;    
    height: 100vh;
    width: 100vw;

}

.main-container-left{
    /* border: 1px solid black; */
    width: 50%;
    height: 100%;   
    display: flex;
    justify-content: center;
    align-items: center;

    background-image: url('../../Assets/Image/1.png');
    background-repeat: no-repeat;
    background-position-x: -190px;
    background-position-y: -90px;
    background-size: 800px;    
}

.main-container-right{
    /* border: 1px solid black; */
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 70px;
}

.main-container-left{
    display: flex;
    flex-direction: column;    
    height: 100%;
    width: 50%;
    align-items: flex-end;
    padding-right: 70px;
}

.left-etapas, .right-etapas{
    /* border: 1px solid green;     */
    height: 550px;
    width: 530px;
    border-radius: 30px;
    background-color: rgb(241, 240, 240, 0.8);
    z-index: 2;
    min-width: 400px;
}

.right-etapas{
    background-color: unset;
    /* border: 1px solid red; */
}

.right-logo{    
    width: 50%;
    display: flex;
    justify-content: flex-end;
    /* border: 1px solid blue; */
    width: 528px;
    margin-top: -80px;    
}

.right-logo img{
    width: 200px;
    height: 80px;
    margin-right: -100px;
}


.main-container-etapa{
    /* border: 1px solid green; */
    padding: 2.5rem;
    height: 100%;
    width: 100%;
}

.container-etapa-header{    
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    /* border: 1px solid red; */
    height: 20%;
    padding-top: 10px;
}

.etapa-header-title hr{
    width: 80px;
    height: 10px;
    border: none;
    margin-top: 0.5rem;
}

.linha-esqueci-a-senha{
    background-color: var(--cor1);
}


.linha-juridico{
    background-color: var(--cor3);
}


.etapa-header-steps{
    display: flex;
    flex-direction: column;
}

.etapa-header-steps div{
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.etapa-header-steps div svg{
    margin: 0.1rem;
}

.etapa-header-steps div svg:hover{
    cursor: pointer;
}

.container-etapa-conteudo{
    display: flex;
    flex-direction: column;
    /* border: 1px solid black;   */
    height: 350px;
    width: 100%;
}

.right-img{
    height: 100%;
    width: 100%;
}

.right-img img{
    width: 100%;
    height: 100%;
    /* margin: 0 auto; */
}

.conteudo-etapa{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}


.conteudo-etapa span{
    text-align: flex-start;
}

.conteudo-etapa input{
    border: none;
    border-bottom: 1px solid var(--cor7); 
    border-radius: 0; 
    outline: none;
    box-shadow: none; 
    background-color: unset;
    width: 100%;
}

.conteudo-etapa input:focus{
    border: none!important;
}

.conteudo-etapa span.informe-codigo{
    display: flex;
    justify-content: center;
}

.card-etapas-botoes{
    border: 1px solid red;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}